import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/assets/css/reset.css'
import rem from '@/assets/js/rem'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
// import VueAwesomeSwiper from 'vue-awesome-swiper'
// import 'swiper/css/swiper.css'
import '@/assets/font/iconfont.css'
import '@/assets/icon/iconfont.css'
import qs from 'qs'
import './appback.js'
import Vant from 'vant'
import 'vant/lib/index.css'
import globle from './globle/globleApi'
import axios from 'axios'

Vue.prototype.globle = globle
axios.defaults.baseURL = globle.baseURL
Vue.prototype.$http = axios
Vue.use(Vant)
// Vue.use(VueAwesomeSwiper)
Vue.prototype.$qs = qs
Vue.config.productionTip = false
Vue.use(ElementUI)
Vue.use(rem)
Vue.use(require('vue-wechat-title'))

router.beforeEach((to, from, next) => {
  if (to.matched.some(m => m.meta.requireAuth)) { // 需要登录
    if (window.localStorage.Username !== '') {
      next()
    } else if (to.path !== '/login') {
      const token = window.localStorage.token
      if (token === 'null' || token === '' || token === undefined) {
        next({ path: '/login' })
        alert('检测到您还未登录,请登录后操作！')
      }
    } else {
      next()
    }
  } else { // 不需要登录
    next()
  }
})

// 监听按键事件
Vue.prototype.$keyBoard = function (vm, methodName, arr) {
  document.onkeydown = function () {
    arr.forEach(val => {
      const key = window.event.keyCode
      if (key === val.key) {
        vm[methodName](val.url) // 触发methodName事件
      }
    })
  }
}

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

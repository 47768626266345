<template>
  <div class="big-wrap" :style="conTop">
    <!-- <navigation></navigation> -->
    <div class="top">
      <div class="circle">
        <div class="left-box">
          <div class="left">
            </div>
          </div>
          <div class="right-box">
            <div class="right">
            </div>
          </div>
          <div class="inner">
            {{allwatchs}}
          </div>
        </div>
      <div class="login" @click="login">登录</div>
      <div class="admins">
        <img :src="headimg" alt="" @click="mymsgs">
        <div class="mymsg" v-show="mymsg">
          <p>个人信息</p>
        </div>
      </div>
    </div>
    <el-row :gutter="20" class="wrap">
      <!-- <el-col :xs="12" :sm="6" :md="6" :lg="6" :xl="6"><div class="grid-content bg-purple-light" @click="$lv.go('/dog/dog')">舔狗日记</div></el-col> -->
      <!-- <el-col :xs="12" :sm="6" :md="6" :lg="6" :xl="6"><div class="grid-content bg-purple" @click="goPersonalIntroduction">响应式</div></el-col> -->
      <!-- <el-col :xs="12" :sm="6" :md="6" :lg="6" :xl="6"><div class="grid-content bg-purple-light" @click="goUrl">网页导航</div></el-col> -->
    </el-row>
    <footer class="footer" @click="gongxinbu('https://beian.miit.gov.cn')">豫ICP备20009133号</footer>
  </div>
</template>
<script>
// import { myfun } from '@/assets/js/text.js'
import { mapState } from 'vuex'
export default {
  name: 'navigations',
  computed: {
    ...mapState({
      Login: 'Login',
      Password: 'Password'
    })
  },
  data () {
    return {
      username: this.$store.state.username,
      Username: this.$store.state.Username,
      headimg: this.$store.state.headimg,
      notshow: false,
      imgurls: '',
      conTop: {},
      show2: false,
      showlogin: false,
      number: this.number,
      password: this.password,
      loginin: this.$store.state.Login,
      welcomes: '欢迎你！' + this.$store.state.Username,
      userid: '',
      timer: null,
      loader: 0,
      mymsg: false,
      watchTimer: 0,
      now: new Date(),
      mdeg: 0,
      hdeg: 0,
      sdeg: 0,
      allwatchs: null, // 首页观看次数
      c: 'si',
      aa: 1
    }
  },
  methods: {
    show () {
      this.show2 = true
    },
    mymsgs () {
      this.mymsg = !this.mymsg
    },
    getinfos () {
      const bgimgs = 'https://api.ixiaowai.cn/gqapi/gqapi.php'
      if (/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
        this.conTop = {
          backgroundImage: 'url(' + bgimgs + ')',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'auto 100%'
        }
      } else {
        this.conTop = {
          backgroundImage: 'url(' + bgimgs + ')',
          backgroundRepeat: 'no-repeat',
          backgroundSize: '100%'
        }
      }
    },
    // getinfo () {
    //   this.axios.get('https://api.vvhan.com/api/acgimg?type=json').then(res => {
    //     this.imgurls = res.data.imgurl
    //     if (/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
    //       this.conTop = {
    //         backgroundImage: 'url(' + res.data.imgurl + ')',
    //         backgroundRepeat: 'no-repeat',
    //         backgroundSize: 'auto 100%'
    //       }
    //     } else {
    //       this.conTop = {
    //         backgroundImage: 'url(' + res.data.imgurl + ')',
    //         backgroundRepeat: 'no-repeat',
    //         backgroundSize: '100%'
    //       }
    //     }
    //     // console.log(this.headimg)
    //     // console.log(this.imgurls)
    //   })
    // },
    // denglu (x) {
    //   this.$http.get(`/getchatusers?userid[x]=${this.number}&password[x]=${this.password}`).then(res => {
    //     this.userid = res.data
    //     if (this.number) {
    //       for (var i = 0; i < res.data.length; i++) {
    //         // console.log(this.userid[i].userid + '--' + this.userid[i].password)
    //         if (Number(this.number) === Number(this.userid[i].userid) && Number(this.password) === Number(this.userid[i].password)) {
    //           this.loginin = true
    //           this.$store.commit('setLogin', true)
    //           this.welcomes = '欢迎你！' + this.userid[i].username
    //           this.Username = this.userid[i].username
    //           this.number = ''
    //           this.password = ''
    //         } else {
    //           console.log('账号密码错误')
    //         }
    //       }
    //     } else {
    //       alert('请输入账号密码。')
    //     }
    //   })
    // },
    login () {
      // this.showlogin = !this.showlogin
      this.$router.push('/login')
      console.log('wocao')
    },
    whether () {
      this.$router.push('/whether')
    },
    goDemoList () {
      this.$router.push('/demoList')
    },
    gongxinbu (url) {
      location.href = url
    },
    goPersonalIntroduction () {
      location.href = 'http://demos.feidream.club'
    },
    flowProgress () {
      this.$refs.redP.style.width = '90%'
    },
    watchTime () {
      this.sdeg = this.sdeg + 6
      this.hdeg = this.now.getHours() * 30
      this.mdeg = this.now.getMinutes() * 6
      this.sdeg = this.now.getSeconds() * 6
      if (this.sdeg >= 360) {
        this.sdeg = 0
        this.mdeg = this.mdeg + 6
        this.$refs.mhand.style.transform = `rotate(${this.mdeg}deg)`
        if (this.mdeg >= 360) {
          this.mdeg = 0
          this.hdeg = this.hdeg + 6
          this.$refs.hhand.style.transform = `rotate(${this.hdeg}deg)`
          if (this.hdeg >= 720) {
            this.hdeg = 0
          }
        }
      }
    },
    async allwatch () {
      this.$http.get('/allwatchs').then(res => {
        this.allwatchs = res.data[0].num + 1
      }).then(() => {
        this.$http.put(`/updatewatchs?num=${this.allwatchs}`).then(val => {
        })
      })
    }
  },
  mounted () {
    // this.flowProgress()
    console.log(this.aa)
    this.allwatch()
    this.getinfos()
    this.watchTime()
    this.$refs.mhand.style.transform = `rotate(${this.mdeg}deg)`
    this.$refs.hhand.style.transform = `rotate(${this.hdeg}deg)`
    setInterval(this.getinfos, 15000)
    setInterval(this.watchTime, 1000)
    this.show()
  },
  watch: {
  }
}
</script>
<style lang="stylus" scoped>
.top
  width 100%
  height .8rem
  .circle
    width: .8rem;
    height: .8rem;
    border-radius: .4rem;
    float left
    position: relative;
    background-color: darkgrey;
    margin-left .2rem
    .left-box,.right-box
      width: .4rem;
      height: .8rem;
      position: absolute;
      overflow: hidden;
    .right-box
      right 0
    .right-box .right,.left-box .left
      transition: all 2s;
      width: .4rem;
      height: .8rem;
    .right-box .right
      border-bottom-right-radius: .4rem;
      border-top-right-radius: .4rem;
      background-color: blue;
      transform-origin: left center;
      transform: rotate(-180deg);
      animation: progress2 2s linear forwards;
      // animation-delay: 2s;
    .left-box .left
      border-bottom-left-radius: .4rem;
      border-top-left-radius: .4rem;
      background-color: blue;
      transform: rotate(-180deg);
      transform-origin: right center;
      animation: progress1 2s linear forwards;
      animation-delay: 2s;
  .login
    width .8rem
    height .8rem
    float right
    margin-right .2rem
    line-height .8rem
    font-size .35rem
    color #fff
    text-align center
    border-radius .4rem
    background-color #FB7299
  .admins
    float right
    // width 3rem
    height .8rem
    color blue
    font-weight 800
    display flex
    align-items center
    // background-color rgba(255,255,255,0.7)
    border-radius .4rem
    position relative
    img
      width .8rem
      height .8rem
      border-radius 50%
    .mymsg
      width 2rem
      position absolute
      top .8rem
      left -0.6rem
      background-color #fff
      z-index 90
@keyframes progress1{
  to{
    transform: rotate(0deg);
  }
}
@keyframes progress2{
  to{
    transform: rotate(0deg);
  }
}
.inner{
  width: .72rem;
  height: .72rem;
  text-align: center;
  line-height: .72rem;
  border-radius: .36rem;
  background-color: white;
  position: absolute;
  /*top: 20px;*/
  /*left: 20px;*/
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}
.dengluzuce
  width 100%
  height 100px
  display none
.showlogin
  display block
.zuce
  width 50%
  height 100px
  float left
.denglu
  width 50%
  height 100px
  float left
  font-weight 800
  color blue
.big-wrap
  height 1600px
  width 100%
  background-attachment: fixed
  background-size: cover
  overflow hidden
.wrap
  opacity 0.8
  padding 2%
  div
    overflow hidden
  .iconfont
    font-size 30px
.el-col
  border-radius: 4px;
  margin-bottom: 20px;
.bg-purple-dark
  background: #99a9bf;
.bg-purple
  background: #d3dce6;
.bg-purple-light
  background: #e5e9f2;
.grid-content
  border-radius: 4px;
  min-height: 1.5rem;
  height 2rem
  line-height 2rem
  text-align center
  font-size 0.4rem
  font-weight 800
.transition-box {
  margin-bottom: 10px;
  width: 2rem;
  height: 2rem;
  border-radius: 4px;
  background-color: #409EFF;
  text-align: center;
  color: black;
  font-weight 800
  font-size 0.5rem
  padding: 0.4rem 0.2rem;
  box-sizing: border-box;
  margin-right: 0.2rem;
}
.footer
  margin-top 5rem
  text-align center
  line-height 1rem
  color white
  background-color grey
  opacity 0.9
.progress-red,.progress-orange,.progress-yellow,.progress-green,.progress-blue
  width 100%
  height 20%
  display block
.progress-red
  background-color red
  width 50%
  animation: progressR 3s linear
  animation-iteration-count: infinite;
.progress-orange
  background-color orange
  width 70%
  animation: progressO 3s linear
  animation-iteration-count: infinite;
.progress-yellow
  background-color yellow
  width 40%
  animation: progressY 3s linear
  animation-iteration-count: infinite;
.progress-green
  background-color green
  width 60%
  animation: progressG 3s linear
  animation-iteration-count: infinite;
.progress-blue
  background-color blue
  width 50%
  animation: progressB 3s linear
  animation-iteration-count: infinite;
@keyframes progressR
  0%
    width 50%
  20%
    width 60%
  40%
    width 20%
  60%
    width 50%
  80%
    width 80%
  100%
    width 50%
@keyframes progressO
  0%
    width 70%
  20%
    width 30%
  40%
    width 50%
  60%
    width 20%
  80%
    width 80%
  100%
    width 70%
@keyframes progressY
  0%
    width 40%
  20%
    width 10%
  40%
    width 30%
  60%
    width 20%
  80%
    width 60%
  100%
    width 40%
@keyframes progressG
  0%
    width 60%
  20%
    width 80%
  40%
    width 50%
  60%
    width 70%
  80%
    width 30%
  100%
    width 60%
@keyframes progressB
  0%
    width 50%
  20%
    width 20%
  40%
    width 70%
  60%
    width 50%
  80%
    width 80%
  100%
    width 50%
.watch-wrap,.mHand,.hHand
  background-size 100%
.mHand,.hHand
  z-index 100
  width 0.2rem
  position absolute
  left 0.8rem
  transform-origin bottom center
.watch-wrap
  height 1.8rem
  width 1.8rem
  margin-left 50%
  position relative
  left -0.9rem
  margin-top 0.1rem
  background-image url('http://elemamf.test.upcdn.net/home/timeW.png')
  z-index 99
  .mHand
    height 0.6rem
    background-image url('http://elemamf.test.upcdn.net/home/timeM.png')
    top 0.3rem
  .hHand
    height 0.4rem
    background-image url('http://elemamf.test.upcdn.net/home/timeM.png')
    top 0.5rem
</style>

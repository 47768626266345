<template>
  <div class="big-wrap">
    <div class="container">
      <div class="navbar-header">
        <!-- LOGO -->
      </div>
      <div class="navbar-collapse">
        <ul class="navbar-nav">
          <li class="menu-item-1">
            <a href="">首页</a>
          </li>
          <li class="menu-item-2">
            <a href="">主体切换</a>
          </li>
          <li class="menu-item-2">
            <a href="">主体切换</a>
          </li>
          <li class="menu-item-2">
            <a href="">主体切换</a>
          </li>
          <li class="menu-item-2">
            <a href="">主体切换</a>
          </li>
          <li class="menu-item-2">
            <a href="">主体切换</a>
          </li>
          <li class="menu-item-2">
            <a href="">主体切换</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'navigation',
  components: {
  },
  data () {
    return {
    }
  }
}
</script>
<style lang="stylus" scoped>
.big-wrap
  width 100%
  border-color #080808
  background-color rgba(10,10,0,0.7)
  box-shadow 0px 0px 8px black
  position: relative;
  z-index: 1000;
  min-height: 30px;
  max-height 58px
  display: block;
  line-height: 1.428571429;
  color: #25292f;
  font-family: "Microsoft Yahei",Helvetica,Arial,sans-serif;
  .container
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    font-size: 14px;
    .container:before
      display: table;
      content: " ";
    .navbar-header
      float left
      width 200px
      height 50px
    .navbar-collapse
      margin-right: 0;
      margin-left: 0;
      display: block !important;
      height: auto !important;
      padding-bottom: 0;
      overflow: visible !important;
      width: auto;
      border-top: 0;
      box-shadow: none;
      max-height: 340px;
      padding-right: 15px;
      padding-left: 15px;
      .navbar-nav
        float: none;
        margin: 0;
        list-style: none;
        display block
        li
          position: relative;
          display: block;
          a
            line-height: 24px;
            position: relative;
            display: block;
            padding: 10px 15px;
            text-decoration: none;
        .menu-item-1
          float: left;
          width 71px
          height 54px
          a
            color: #ffffff;
            background-color: rgba(138,43,226,0.7) !important;
            padding-top: 15px;
            padding-bottom: 15px;
            line-height: 24px;
            position: relative;
            display: block;
            padding: 10px 15px;
            text-decoration: none;
            cursor: pointer;
        .menu-item-2
          float: left;
          width 101px
          height 54px
          a
            width 71px
            height 24px
            color: #ffffff;
            padding-top: 15px;
            padding-bottom: 15px;
</style>

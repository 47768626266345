<template>
  <div class="big-wrap" :style="conTop">
    <navigation></navigation>
    <div class="lines">
      <router-link :to="{path:'/who'}" class="changeone">
        WHO ARE YOU
      </router-link>
    </div>
    <div class="changetwo">
      <input type="text" class="inpVal">
    </div>
  </div>
</template>
<script>
import navigation from './components/navigation'
export default {
  name: 'home',
  components: {
    navigation
  },
  data () {
    return {
      conTop: {
        backgroundImage: 'url(' + require('@/assets/img/b721f74df6bac6d22b9bf4a0402d6d3b.jpg') + ')',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100%'
      },
      notshow: false,
      songs: [],
      imgurls: []
    }
  },
  methods: {
    getinfo () {
      this.axios.get('/api/api/api.php?return=json').then(res => {
        this.imgurls = res.data.imgurl
        console.log(this.imgurls)
      })
    },
    wangyy () {
      this.axios.get('https://v1.alapi.cn/api/music/search?keyword=爱河').then(res => {
        console.log(res)
        if (res.data.code === 200) {
          this.songs = res.data.data.songs
        }
      }, error => {
        console.log(error)
      })
    }
  },
  mounted () {
    this.getinfo()
    this.wangyy()
  }
}
</script>
<style lang="stylus" scoped>
.big-wrap
  height 1600px
  width 100%
  background-attachment: fixed
  background-size: cover
  .lines
    height 100px
    width 500px
    margin-left 800px
    border 2px solid orange
    text-align center
    border-radius 100px
    .changeone
      width 400px
      height 300px
      font-size 80px
      line-height 100px
      margin-left 0px
  .changetwo
    width 90%
    margin-left 5%
    height 50px
    border 2px solid red
    font-size 50px
    .inpVal
      width 100%
      height 100%
      opacity 0.3
  .songs-list
    width 80%
    margin-left 10%
    height 100px
</style>

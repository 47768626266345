<template>
  <div class="bigwrap" ref="bigwrapm">
    <div v-wechat-title="$route.meta.title"></div>
    <!-- <div :style="TheStyle" class="mouseimg" style="pointer-events: none;"> -->
      <!-- <img src="@/assets/img/texiao1.gif" class="imggs" alt=""> -->
    <!-- </div> -->
    <!-- 导航页 -->
    <navmenu class="navmenu"></navmenu>
    <!-- 空白隔开 -->
    <div style="height:0.3rem;width:100%"></div>
    <!-- 文章列表 -->
    <div class="art">
      <myarticle class="newArticle"></myarticle>
      <div class="hotArticle">
        <img src="../../assets/img/guanggao.webp" alt="">
      </div>
    </div>
  </div>
</template>
<script>
import navmenu from './components/navmenu.vue'
import myarticle from './components/article.vue'
// import myfooter from './components/myfooter.vue'
export default {
  name: 'minebook',
  inject: ['reload'],
  components: {
    navmenu,
    myarticle
    // myfooter
  },
  data () {
    return {
      // x: '',
      // y: '',
      // TheStyle: {
      //   left: '',
      //   top: '',
      //   opacity: ''
      // },
      winheight: null
      // conTop: {
      //   backgroundImage: 'url(' + require('@/assets/img/winter.jpg') + ')',
      //   backgroundRepeat: 'no-repeat',
      //   backgroundSize: `100%, ${this.winheight}`
      // },
    }
  },
  computed: {
  },
  methods: {
    // showartdetail () {
    //   this.artdetail = !this.artdetail
    // },
    // getXY (event) {
    //   // event.preventDefault()
    //   this.x = event.screenX
    //   this.y = event.screenY
    //   // console.log(event)
    //   this.TheStyle.left = this.x - 30 + 'px'
    //   this.TheStyle.top = this.y - 130 + 'px'
    // },
    // outXY1 () {
    //   this.TheStyle.left = ''
    //   this.TheStyle.top = ''
    // },
    // outXY () {
    //   setTimeout(this.outXY1, 1000)
    // },
    async allwatch () {
      this.$http.get('/allwatchs').then(res => {
        this.allwatchs = res.data[0].num + 1
      }).then(() => {
        this.$http.put(`/updatewatchs?num=${this.allwatchs}`).then(val => {
        })
      })
    },
    autodivheight () { // 函数：获取尺寸
      // 获取浏览器窗口高度
      var winHeight = 0
      if (window.innerHeight) {
        winHeight = window.innerHeight
      } else if ((document.body) && (document.body.clientHeight)) {
        winHeight = document.body.clientHeight
        // 通过深入Document内部对body进行检测，获取浏览器窗口高度
      }
      if (document.documentElement && document.documentElement.clientHeight) {
        winHeight = document.documentElement.clientHeight
      }
      // DIV高度为浏览器窗口的高度
      this.winheight = winHeight + 'px'
      // 当tree的高度超过屏幕高度的时候，设置滚动条
      // this.$refs.bigwrapf.height() < winHeight ? this.$refs.bigwrapf.height('auto') : this.$refs.bigwrapf.css('overflow', 'auto').height(winHeight)
    }
  },
  mounted () {
    this.allwatch()
    window.οnresize = this.autodivheight() // 浏览器窗口发生变化时同时变化DIV高度
    // if (this.pinkui === true) {
    //   if (/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
    //     this.conTop = {
    //       backgroundImage: 'url(' + require('@/assets/img/pinkui1.jpg') + ')',
    //       backgroundRepeat: 'no-repeat',
    //       backgroundSize: '25rem,100%'
    //     }
    //   } else {
    //     this.conTop = {
    //       backgroundImage: 'url(' + require('@/assets/img/pinkui1.jpg') + ')',
    //       backgroundRepeat: 'no-repeat',
    //       backgroundSize: '100%,100%'
    //     }
    //   }
    // }
  }
}
</script>
<style lang="stylus" scoped>
.bigwrap
  position relative
  width 100%
  overflow hidden
  // background-color skyblue
  background-attachment: fixed
  background-size: cover
.imggs
  width 100%
  height 100%
.mouseimg
  position fixed
  width 0.8rem
  height 0.8rem
  backgroung-color red
  z-index 999
  opacity 1
  // transition all .9s ease
.navmenu
  z-index 999
  opacity 0.9
  width 100%
  position fixed
  top 0
  left 0
.art // 文章列表
  // margin-bottom .5rem
  margin-top 0.5rem
  z-index 996
  opacity 0.8
  display: flex
  padding: 0 6%
  box-sizing: border-box
  justify-content: space-between
  .newArticle
    width: 70%
    margin-top 20px
  .hotArticle
    width: 30%
    height 300px
    margin-top 40px
    margin-left: 20px
    img
      width: 100%
      height: auto
      max-height: 100%
input
  border-radius 0.3rem
  background-color yellow
  color blue
  text-indent 0.5em
textarea
  color blue
  background-color yellow
  text-indent 0.5em
  border-radius 0.3rem
.bigadd
  display block
  height 1rem
  width 1rem
  line-height  1rem
  border-radius 50%
  text-align center
  background-color yellow
  color black
  font-size 0.8rem
  margin 0 auto
.xuehua1
  width 100%
  height auto
  position fixed
  top -26rem
  left 0
  z-index 995
  animation myfirst 10s linear
  animation-direction alternate
@keyframes myfirst
    0%
      left 0
      top -20rem
    100%
      left 0
      top 20rem
.xuehua2
  width 100%
  height auto
  position fixed
  z-index 995
  top -15rem
  left 1rem
  animation mysecond 18s infinite
  animation-direction alternate
@keyframes mysecond
  0%
    left -3rem
    top -15rem
  100%
    left 0
    top 10rem
.xuehua3
  width 100%
  height auto
  z-index 995
  position fixed
  top -20rem
  left 2rem
  animation mythird 25s infinite
  animation-direction alternate
@keyframes mythird
  0%
    left 3rem
    top -20rem
  100%
    left 0
    top 0rem
.disnone
  display none
</style>

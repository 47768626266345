<template>
  <div>
    <h3>请输入验证码</h3>
    <input type="text" v-model="data" @keyup.13="check">
  </div>
</template>
<script>
export default {
  name: 'vd',
  data () {
    return {
      data: null
    }
  },
  methods: {
    check () {
      this.axios.get('').then((res) => {
        console.log(res.data)
        if (res.data.code === 200) {
          // 52
        }
      })
    }
  }
}
</script>

<template>
  <div>
    <div class="checkPhone" v-show="!isShow" @click="showNum">手机号登录</div>
    <div v-show="isShow">
      <div class="inp">
        <span>+86</span>
        <input type="text" v-model="num">
      </div>
      <div class="next" @click="checkInp(num)">
        <button>下一步</button>
      </div>
      <p class="arr" ref="arr"></p>
    </div>
  </div>
</template>
<script>
export default {
  name: 'login',
  data () {
    return {
      num: null,
      isShow: false
    }
  },
  methods: {
    showNum () {
      this.isShow = true
    },
    checkInp (num) {
      const exg = /^1([38]\d|5[0-35-9]|7[3678])\d{8}$/
      if (exg.text(num)) { // 验证通过
        this.axios.get('').then((res) => {
          if (res.data.code === 200) {
          // 验证页面
            if (res.data.nickname) {
              this.$router.push({ name: 'pwd', params: { phone: num } })
            } else {
              this.axios.get('').then((res) => {
                if (res.data.code === 200) {
                  this.$router.push({ name: 'vd', params: { phone: num } })
                }
              })
            }
          }
        })
        alert(1)
      } else {
        this.$refs.arr.innerHTML = '请输入11位数字号码'
      }
    }
  }
}
</script>
<style lang="stylus" scoped>

</style>

<template>
  <div>
    <router-view v-if="showRouter" key="$route.fullPath"></router-view>
  </div>
</template>
<script>
export default {
  name: 'app',
  provide () {
    return {
      reload: this.reload
    }
  },
  data () {
    return {
      showRouter: true
    }
  },
  methods: {
    reload () {
      this.showRouter = false
      this.$nextTick(function () {
        this.showRouter = true
      })
    }
    // getinfo () {
    //   this.axios.get('/api/api/api.php?return=json').then(res => {
    //     console.log(res)
    //   })
    // },
    // wangyy () {
    //   this.axios.get('https://v1.alapi.cn/api/music/search?keyword=爱河').then(res => {
    //     console.log(res)
    //   })
    // }
  },
  mounted () {
    // this.getinfo()
    // this.wangyy()
  }
}
</script>
<style lang="stylus">
// .el-select-dropdown__item:active{
//     color: #409EFF;
//     font-weight: 700;
//     :after{
//       content: "";
//       position: absolute;
//       -webkit-font-smoothing: antialiased;
//       -moz-osx-font-smoothing: grayscale;
//       right: 16px;
//       top: 9px;
//       width: 10px;
//       height: 10px;
//       border-radius: 50%;
//       background-color: #0486FE;
//       border: 3.5px solid #ADD5FC;
//     }
// }
// ------------选择器输入框开始---------
.selected:after{
    position: absolute;
    right: 20px;
    font-family: element-icons;
    content: "\e6da";
    font-size: 12px;
    font-weight: 700;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.el-select-dropdown__item{
  padding-right: 42px !important;
  // max-width: 364px;
}
.el-cascader-node.is-selectable.in-active-path{
  color: #409EFF !important;
}
.el-cascader-menu{
  max-width: 280px;
  min-width: 230px !important;
}
.el-cascader-node__postfix{
  width: 20px;
  background-color: #fff;
  z-index: 10;
}
.el-input__inner{ // 输入框背景色
  background-color :rgba(243, 247, 255, 1) !important;
}
.el-icon-arrow-up:before { // 选择器下箭头换成三角箭头
    content: "" !important;
}
// ------------选择器输入框结束---------
// ------------级联选择器开始---------
.el-cascader-node.is-selectable.in-checked-path.is-active:after{ // 级联选择框蓝色对钩
  position: absolute;
  right: 20px;
  font-family: element-icons;
  content: "\e6da";
  font-size: 12px;
  font-weight: 700;
  z-index: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.el-cascader-node__prefix{
  right:10px !important;
}
.el-cascader-node{ // 级联选择器左padding
  padding-left:10px !important;
}
// 级联选择框右箭头加背景色
.el-cascader-node:not(.is-disabled):hover .el-cascader-node__postfix{
  background-color: #F5F7FA;
}
.el-cascader-node:not(.is-disabled):focus .el-cascader-node__postfix{
  background-color: #F5F7FA;
}
.el-cascader-menu__wrap{ // 级联选择器盒子高
  height 268px !important;
}
// 自制级联选择器
.al-cascader{
  display: inline-block;
  position: relative;
  font-size: 14px;
  line-height: 40px;
  width 224px
}
.al-cascader__dropdown{
  margin: 5px 0;
  font-size: 14px;
  background: #FFF;
  border: 1px solid #E4E7ED;
  border-radius: 4px;
  box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);
  z-index: 2015;
  position: absolute;
  top: 1312px;
  left: 100px;
  display none
}
.dropdownArror{
  display block !important
}
.al-active{
  color #409EFF
  font-weight: 700;
}
// ------------级联选择器结束---------
// -------------时间选择器开始--------------
.el-range-input{ // 时间输入框
  background-color :rgba(243, 247, 255, 1) !important;
}
// 时间范围选择器图标位置
.el-date-editor .el-range__icon{
  position: absolute;
  left: 100%;
  margin-left: -30px !important;
  margin-top: 0px;
}
.el-date-table td span{ // 选中时间设置成方形
  border-radius 0 !important
}
.el-date-table td.today span{ // 当天时间样式
  border 1px solid rgba(64, 158, 255, 1)
}
// ------------时间日期样式结束------------
// input框末尾文字按钮start
.al-forgetName{
  position relative
}
.al-forgetNameBut{
  position absolute
  right 10px
  top 0px
  color rgba(64, 158, 255, 1) !important
  height 22px
}
// input框末尾文字按钮end
// 列表start
.el-input__prefix{ // 日期图标换到尾部
  position: absolute !important;
  left: 100% !important;
  margin-left: -30px;
}
.el-input--prefix .el-input__inner{ // 日期选择器文字离左边框的距离
  padding: 0 15px !important;
}
// /deep/.el-date-editor .el-range__icon{
//   position: absolute;
//   left: 100%;
//   margin-left: -30px;
//   margin-top: -4px;
// }
.el-table .cell, .el-table--border td:first-child .cell, .el-table--border th:first-child .cell{
  padding-left: 20px !important;
  padding-right: 20px !important;
}
.el-table__body{
  background-color: #fff;
  border-spacing: 0px 5px;
  margin-top: -5px;
  border-collapse: separate; // 设置该属性可以让列表的上下间距生效
}
.el-pagination__total{
  float: left;
}
.el-pagination__sizes{
  float: left;
  .el-input__inner{
    background-color #fff !important
  }
}
.el-pagination__jump .el-input__inner{
  background-color #fff !important
}
.body-wrapper .el-table th, .el-table tr{
  background-color #FAFCFF !important
}
// 列表end
</style>
<style lang="less">

</style>

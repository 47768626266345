import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)
// let userInfo = {
//   imgUrl: '',
//   nickname: '',
//   token: ''
// }
// if (localStorage.userInfo) {
//   userInfo = JSON.parse(localStorage.suerInfo)
// }
const state = { // 全局管理的数据存储
  idx: 0,
  playlist: {},
  isShowMiniPlayer: false,
  isShowList: false, // 控制显示播放页
  initAudio: {
    url: '',
    duration: 0,
    currentTime: 0,
    ended: false,
    progress: '0%'
  },
  playingLyric: null,
  Login: false,
  Username: window.localStorage.getItem('Username'),
  headimg: window.localStorage.getItem('headimg'),
  Password: '',
  isLogin: '0',
  ser: null,
  sendart: false,
  token: localStorage.getItem('token') ? localStorage.getItem('token') : '', // token
  username: window.localStorage.getItem('username'),
  // 登录状态
  loginStatus: !!window.localStorage.getItem('loginStatus'),
  ceshi: 'ceshi1234'
}
export default new Vuex.Store({
  namespaced: true,
  state,
  getters: { // 监听数据变化的
    // getStorage (state) { // 获取本地存储的登录信息
    //   if (!state.token) {
    //     state.token = JSON.parse(localStorage.getItem())
    //   }
    //   return state.token
    // }
  },
  mutations: {
    $_setToken (state, value) { // 设置存储token
      state.token = value
      localStorage.setItem('token', value)
    },
    $_removeStorage (state, value) { // 删除token
      localStorage.removeItem('token')
    },
    sendLrc (state, t) {
      state.playingLyric = t
    },
    sendCurT (state, t) {
      state.initAudio.currentTime = t
      state.initAudio.progress = parseInt(t) / state.initAudio.duration * 100 + '%'
    },
    sendDt (state, d) {
      state.initAudio.duration = d
    },
    changeIdx (state, index) {
      state.idx = index
    },
    index (state, data) {
      state.playlist.index = data.index
      if (data.list) {
        state.playlist.list = data.list
      }
      state.isShowList = true
    },
    changeList (state) {
      state.isShowMiniPlayer = true
    },
    showFull (state) {
      state.isShowList = true
      state.isShowMiniPlayer = false
    },
    sendUrl (state, url) {
      state.initAudio.url = url
    },
    setLogin (state, Login) {
      state.Login = Login
    },
    setUsername (state, Username) {
      state.Username = Username
    },
    setHeadimg (state, headimg) {
      state.headimg = headimg
    },
    setPassword (state, Password) {
      state.Password = Password
    },
    setSendart (state, sendart) {
      state.sendart = sendart
    },
    getUser (state, username) {
      console.log('username', username)
      state.username = username
    },
    getLoginStatus (state, flag) {
      state.loginStatus = flag
    },
    setCeshi (s, d) {
      s.ceshi = d
    }
  },
  actions: {
    getUrl (ctx, obj) {
      obj.that.axios.get('https://v2.alapi.cn/api/music/url?token=ktuMtvvukd8zK9uo&id=' + obj.id).then((res) => {
        if (res.data.code === 200) {
          ctx.commit('sendUrl', res.data.data[0].url)
        }
      })
    }
  },
  modules: {
  }
})

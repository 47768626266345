<template>
  <div>
    <h3>请输入密码</h3>
    <input type="password" v-model="pwd" @keyup.enter="checkPhone(pwd)">
  </div>
</template>
<script>
export default {
  name: 'pwd',
  data () {
    return {
      pwd: null
    }
  },
  methods: {
    checkPhone (val) {
      this.axios.get(`/api/login/cellphone?phone=${this.$route.parse.phone}&password=${val}`).then((res) => {
        console.log(res.data)
        if (res.data.code === 200) {
          if (res.data.token) {
            const obj = {
              imgUrl: res.data.profile.avatarUrl,
              nickname: res.data.profile.nickname,
              token: res.data.token
            }
            localStorage.userInfo = JSON.stringify(obj)
            this.$route.push('/')
          }
        }
      })
    }
  }
}
</script>
